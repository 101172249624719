import { configEnv, EnvName } from './configEnv';

const getEnv = () => {
    let env = EnvName.DEV;
    if (process.env.REACT_APP_ENV === EnvName.PROD) {
        env = EnvName.PROD;
    }
    return configEnv[env];
};

export default getEnv;
