import ErrorImg from 'assets/images/error.svg'
import CheckedImg from 'assets/images/checked.svg'

const removeOldMessage = () => {
    const oldMessage = Array.from(document.querySelectorAll('div.message'))
    if (oldMessage && oldMessage.length > 0) {
        for (let i = 0; i < oldMessage.length; i++) {
            oldMessage[i].remove()
        }
    }
}
export const message = (status: 'success' | 'error', message: string) => {
    removeOldMessage()
    let icon = ''
    if (status === 'success') icon = CheckedImg
    if (status === 'error') icon = ErrorImg

    const temp = `<div class="message fixed top-5 z-50 max-w-max px-5 py-3 bg-white rounded-lg" style="left:50%; transform:translateX(-50%); box-shadow:0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d">
        <div class="flex flex-row justify-start items-center">
          <img src="${icon}" alt="" style="width: 16px; height:16px;" class="mr-3">
          <p>${message || ''}</p>
        </div>
      </div>`
    const target = document.getElementsByTagName('body')[0];
    target.insertAdjacentHTML('beforeend', temp)
    setTimeout(() => {
        removeOldMessage()
    }, 5000)
}