import React, {useCallback, useEffect, useState} from 'react';
import ReactPaginateFB from "../pagination/ReactPaginate";
import {IDatatable, IPagination} from "./Datatable.interface";
import Empty from "../Empty/Empty";
import loadingImg from 'assets/images/loading-pubple.svg'

export enum DEFAULT_PAGINATE {
    page = 1,
    limit = 20,
}

const Datatable = function <Record>(props: IDatatable<Record>) {
    const {columns, dataSource, pagination, loading} = props
    const [mounted, setMounted] = useState(false)
    const [internalPagination, setInternalPagination] = useState<IPagination>({total: 0})

    useEffect(() => {
        setMounted(true)
    }, [])

    useEffect(() => {
        if (!mounted) return
        setInternalPagination(prev => ({...prev, total: pagination?.total}))
        // eslint-disable-next-line
    }, [pagination])

    const tableWith = useCallback(() => {
        return columns.reduce((sum, curr) => sum + curr.width, 0)
    }, [columns])

    return (
        <div>
            <ReactPaginateFB
                total={internalPagination.total}
            />

            <div className="overflow-x-auto max-w-[100vw] shadow ring-1 ring-black ring-opacity-5 md:rounded-lg my-5 relative">
                {loading && <img src={loadingImg} alt="" className={'w-14 absolute top-1/4 left-1/2 right-1/2 z-10'}/>}
                <table className={`w-full divide-y divide-gray-300 ${loading ? 'opacity-40' : ''}`} style={{minWidth: tableWith()}}>
                    <thead className="bg-gray-50">
                    <tr>
                        {columns.map((column, index) =>
                            <th scope="col" key={index} className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900`} style={column.width ? {width: column.width} : undefined}>
                                {column.title}
                            </th>
                        )}
                    </tr>
                    </thead>
                    <tbody className="bg-white">
                    {!dataSource.length ?
                        (
                            <tr className={'w-full h-28'}>
                                <td className="whitespace-nowrap w-full text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 absolute left-0 bottom-0">
                                    <Empty />
                                </td>
                            </tr>
                        ) :
                        dataSource.map((data: any, index) => {
                            return (
                                <tr key={index} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                    {columns.map((column, colIndex) =>
                                        (
                                            <td key={index + '.' + colIndex} className="whitespace-normal py-4 pl-4 pr-3 text-left text-sm font-normal text-gray-900">
                                                {column.render ? column.render(data[column.dataIndex], data) : data[column.dataIndex]}
                                            </td>
                                        )
                                    )}
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>

            <ReactPaginateFB
                total={internalPagination.total}
            />
        </div>
    );
};

export default Datatable;