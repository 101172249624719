import React from 'react';
import EmptyImg from 'assets/images/BoxEmpty.svg'

const Empty = () => {
    return (
        <div>
            <img src={EmptyImg} alt="" className={'mx-auto'}/>
            <div>No Data</div>
        </div>
    );
};

export default Empty;