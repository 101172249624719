import React, {useCallback, useEffect, useState} from 'react';
import ReactPaginate from "react-paginate";
import {DEFAULT_PAGINATE} from "../DataTable/Datatable";
import useQueryParams from "../../hooks/useQueryParams";

interface IPaginateProps {
    total?: number
    itemPerPage?: number
}

const pages = [20,40,60,80,100]
const ReactPaginateFB = (props: IPaginateProps) => {
    const {total = 0} = props
    const [queryParams, setQueryParamsAndPush] = useQueryParams()
    const [pageCount, setPageCount] = useState(0);
    const filters: any = queryParams
    const current = queryParams.page ? queryParams.page : DEFAULT_PAGINATE.page
    const limit = queryParams.limit || DEFAULT_PAGINATE.limit

    const showingFrom = useCallback(() => {
        if (current > pageCount) return 0
        const showing = (current - 1) * limit
        return showing >= 0 && total > 0 ? showing + 1 : 0
    }, [current, limit, total, pageCount])

    const showingTo = useCallback(() => {
        if (current > pageCount) return 0
        const showing = current * limit
        return showing < total ? showing : total
    }, [current, limit, total, pageCount])

    useEffect(() => {
        setPageCount(Math.ceil(total / limit));
    }, [total, limit]);

    const handlePageClick = (event: any) => {
        let params = queryParams
        params = {...params, page: event.selected + 1}
        setQueryParamsAndPush(params, true)
    };

    if (!total) return <div></div>

    return (
        <div className={'flex flex-col items-end sm:flex-row px-5 sm:px-0 sm:flex-1 sm:items-center sm:justify-between'}>
            <div className={'mb-2'}>
                <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{showingFrom()}</span> to <span className="font-medium">{showingTo()}</span> of{' '}
                    <span className="font-medium">{total}</span> results
                </p>
            </div>
            <div className={'flex flex-row'}>
                <ReactPaginate
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    pageCount={pageCount}
                    forcePage={current - 1}
                    previousLabel="<"
                    pageClassName="page-item bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative border"
                    pageLinkClassName="page-link px-4 py-2 text-sm font-medium inline-flex items-center"
                    previousClassName="page-item rounded-l-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50"
                    previousLinkClassName={`page-link relative inline-flex items-center px-2 py-2 text-sm font-medium w-8 justify-center ${parseInt(current) === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
                    nextClassName="page-item rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    nextLinkClassName={`page-link relative inline-flex items-center px-2 py-2 w-8 justify-center ${parseInt(current) === pageCount ? 'cursor-not-allowed opacity-50' : ''}`}
                    breakLabel="..."
                    breakClassName="page-item border border-gray-300 bg-white text-sm font-medium text-gray-700"
                    breakLinkClassName="page-link relative inline-flex items-center px-4 py-2"
                    containerClassName="pagination relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    activeClassName="active z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                    // renderOnZeroPageCount={null}
                />

                <select
                    className="block w-20 pl-2 pr-5 py-2 ml-1 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    value={filters?.limit ?? 20}
                    onChange={(e) => {
                        setQueryParamsAndPush({...queryParams, page: 1, limit: parseInt(e.target.value)})
                    }}
                >
                    {!pages.includes(parseInt(queryParams.limit)) ? <option value={queryParams.limit} key={queryParams.limit}>{queryParams.limit}</option> : null}
                    {pages.map(p => <option value={p} key={p}>{p}</option>)}
                </select>
            </div>
        </div>
    );
};

export default ReactPaginateFB;