import { IConfigEnv } from './config.interface';

export enum EnvName {
  DEV = 'development',
  STAG = 'staging',
  FEATURE = 'feature',
  PROD = 'production'
}

export const configEnv: IConfigEnv = {
  [EnvName.DEV]: {
    environment: EnvName.DEV,
    app: {
      url: 'https://dev.guest-insight.fastboy.dev',
      featureFlag: false,
      name: 'fastboy-client',
      version: '1.0.0-dev+3',
      graphqlUrl: 'https://sys.dev.crm.fastboy.dev/api/graphql'
    },
  },
  [EnvName.FEATURE]: {
    environment: EnvName.FEATURE,
    app: {
      url: 'https://dev.guest-insight.fastboy.dev',
      featureFlag: false,
      name: 'fastboy-client',
      version: '1.0.0-feature',
      graphqlUrl: 'https://sys.dev.crm.fastboy.dev/api/graphql'
    },
  },
  [EnvName.STAG]: {
    environment: EnvName.STAG,
    app: {
      featureFlag: false,
      url: 'https://guest-insight.fastboy.dev',
      name: 'fastboy-client',
      version: '1.0.0-stag+3',
      graphqlUrl: 'https://sys.crm.fastboy.dev/api/graphql'
    },
  },
  [EnvName.PROD]: {
    environment: EnvName.PROD,
    app: {
      url: 'https://guest-insight.fastboy.dev',
      featureFlag: false,
      name: 'fastboy-client',
      version: '1.0.0',
      graphqlUrl: 'https://sys.crm.fastboy.dev/api/graphql'
    },
  },
};
