import Datatable, {DEFAULT_PAGINATE} from "components/DataTable/Datatable";
import Logo from 'assets/images/logo.png'
import {clientRequest} from "../../common/clientRequest";
import {useEffect, useState} from "react";
import HomeFilter, {IHomeFilter} from "./Home.filter";
import {IPagination} from "../../components/DataTable/Datatable.interface";
import {countryCodeObj} from "../../constants/countryCode";
import useQueryParams from "../../hooks/useQueryParams";
import {message} from "../../common/commons";

import canadaData from 'assets/states/canada.json';
import usData from 'assets/states/us.json';

const canadaList = JSON.parse(JSON.stringify(canadaData));
const usList = JSON.parse(JSON.stringify(usData));

export const Home = () => {
    const [queryParam, setQueryParamsAndPush] = useQueryParams()
    const [companies, setCompanies] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [paginate, setPaginate] = useState<IPagination>({total: 0})

    const fetching = async (filters: any = {}, limit: number = DEFAULT_PAGINATE.limit, offset: number = 0) => {
        setLoading(true)
        try {
            const data = await clientRequest({
                body: {
                    "query": "query GetCompanies($where: _custom_list_company_bool_exp, $limit:Int, $offset: Int) {\n  listCompany: _custom_list_company(limit: $limit, offset: $offset, order_by: {company_name: asc}, where: $where) {\n    address\n    business_phone\n    city\n    company_name\n    country\n    owner_name\n    owner_phone\n    postal_code\n    state\n  }\n  listCompanyAggregate: _custom_list_company_aggregate(where: $where) {\n    aggregate {\n      count\n    }\n  }\n}\n",
                    "variables": {
                        "limit": limit,
                        "offset": offset,
                        "where": filters
                    },
                    "operationName": "GetCompanies"
                },
                method: 'POST'
            })
            if (data.errors) {
                throw data.errors
            }
            setCompanies(data.data.listCompany)
            setPaginate({
                total: data.data?.listCompanyAggregate?.aggregate?.count || 0,
            })
        } catch (errors: any) {
            console.log(errors)
            message('error', errors[0].message)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (!queryParam.page || !queryParam.limit) {
            setQueryParamsAndPush({page: queryParam.page || DEFAULT_PAGINATE.page, limit: queryParam.limit || DEFAULT_PAGINATE.limit})
            return
        }
        const params: any = queryParam
        const {limit, page, ...filters} = params
        fetching(convertValueFilter(filters), parseInt(limit), limit*(page-1))
        // eslint-disable-next-line
    }, [queryParam])

    const convertValueFilter = (filters: IHomeFilter) => {
        let filtersConvert: { [key: string]: any } = {}
        Object.keys(filters).map(filterKey => {
            if (!filters[filterKey as keyof typeof filters]) return filterKey
            switch (filterKey) {
                case 'companyName':
                    filtersConvert['company_name'] = {_ilike: `%${filters[filterKey]}%`}
                    break
                case 'businessPhone':
                    filtersConvert['business_phone'] = {_ilike: `%${filters[filterKey]}%`}
                    break
                case 'ownerName':
                    filtersConvert['owner_name'] = {_ilike: `%${filters[filterKey]}%`}
                    break
                case 'ownerPhone':
                    filtersConvert['owner_phone'] = {_ilike: `%${filters[filterKey]}%`}
                    break
                case 'city':
                    filtersConvert['city'] = {_ilike: `%${filters[filterKey]}%`}
                    break
                case 'state':
                    const country = filters['country']
                    if (country === 'US' || country === 'CA') {
                       const stateList = country === 'US' ? usList : canadaList
                        const selectedState = stateList.find((item: any) => item.abbreviation === filters[filterKey])
                        filtersConvert['_or'] = [
                            {state: {_ilike: selectedState?.name ? `%${selectedState?.name}%` : `%${filters[filterKey]}%`}},
                            {state: {_eq: filters[filterKey]}}
                        ]
                    } else {
                        filtersConvert['_or'] = [
                            {state: {_ilike: `%${filters[filterKey]}%`}},
                        ]
                    }
                    // filtersConvert['state'] = {_ilike: `%${filters[filterKey]}%`}
                    break
                case 'country':
                    filtersConvert['country'] = {_eq: filters[filterKey]}
                    break
                case 'address':
                    filtersConvert['address'] = {_ilike: `%${filters[filterKey]}%`}
                    break
                case 'postalCode':
                    filtersConvert['postal_code'] = {_ilike: `%${filters[filterKey]}%`}
                    break
            }
            return filterKey
        })
        return filtersConvert
    }

    return (
        <div className="w-full py-2 md:px-6 lg:px-8 pt-8 pb-10">
            <div className="sm:flex sm:items-center px-5 sm:px-0">
                <div className="sm:flex-auto">
                    <div className={'flex justify-center'}>
                        <img src={Logo} alt="" className={'w-10'}/>
                        <h2 className="ml-4 text-center text-2xl font-black" style={{color: 'rgb(16, 45, 89)'}}>Fastboy</h2>
                    </div>
                    <p className="mt-2 text-sm text-gray-700 text-center">
                        A list of all the client company of Fastboy.
                    </p>
                </div>
            </div>
            <HomeFilter />

            <div id={'post-container'} className={'w-full'}></div>

            <Datatable
                loading={loading}
                dataSource={companies}
                pagination={{
                    total: paginate.total,
                }}
                columns={[
                    {
                        title: 'Company Name',
                        dataIndex: 'company_name',
                        width: 200
                    },
                    {
                        title: 'Business Phone',
                        dataIndex: 'business_phone',
                        width: 200,
                        render: (value) => <span>{value ? `******${value}` : '-'}</span>
                    },
                    {
                        title: 'Owner Name',
                        dataIndex: 'owner_name',
                        width: 200
                    },
                    {
                        title: 'Owner Phone',
                        dataIndex: 'owner_phone',
                        width: 150,
                        render: (value) => <span>{value ? `******${value}` : '-'}</span>
                    },
                    {
                        title: 'City',
                        dataIndex: 'city',
                        width: 200
                    },
                    {
                        title: 'State',
                        dataIndex: 'state',
                        width: 200,
                        render: (value, record) => {
                            if (record.country === 'US' || record.country === 'CA') {
                                const stateList = record.country === 'US' ? usList : canadaList
                                const currentState = stateList.find((item: any) => item.abbreviation === value)
                                if (currentState) {
                                    return <span>{currentState.name}</span>
                                } else {
                                    return <span>{value}</span>
                                }
                            } else {
                                return <span>{value}</span>
                            }
                        }
                    },
                    {
                        title: 'Country',
                        dataIndex: 'country',
                        width: 200,
                        render: (value) => {
                            return <div>{countryCodeObj[value as keyof typeof countryCodeObj]?.name || value}</div>
                        }
                    },
                    {
                        title: 'Address',
                        dataIndex: 'address',
                        width: 250
                    },
                    {
                        title: 'Postal Code',
                        dataIndex: 'postal_code',
                        width: 150,
                    }
                ]}
            />

        </div>
    )
}

export default Home;