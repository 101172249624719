import React from 'react';
import './App.css';
import Home from "./Pages/Home/Home";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

function App() {
  return (
      <Router>
          <div>
              <Switch>
                  <Route path="/">
                      <Home />
                  </Route>
              </Switch>
          </div>
      </Router>
    // <div className="App">
    //   <Home />
    // </div>
  );
}

export default App;
