import getEnv from "../configs";

interface IClientRequest {
    url?: string
    params?: object
    body?: object,
    method?: 'GET' | 'POST'
}

const clientRequest = ( config: IClientRequest ) => {
    let url = config.url ?? getEnv().app.graphqlUrl
    let options: any = {
        method: config.method ?? 'GET'
    };
    if ( 'GET' === options.method && config.params ) {
        url += '?' + ( new URLSearchParams( config.params as any ) ).toString();
    } else {
        options.body = JSON.stringify( config.body );
    }

    return fetch( url, options ).then( response => response.json() );
};

export {
    clientRequest
}