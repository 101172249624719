import React, {useEffect, useState} from 'react';
import {countryCodeArr} from "../../constants/countryCode";
import FilterImg from 'assets/images/filter.svg'
import useQueryParams from "../../hooks/useQueryParams";
import {DEFAULT_PAGINATE} from "../../components/DataTable/Datatable";

import canadaData from 'assets/states/canada.json';
import usData from 'assets/states/us.json';

const canadaList = JSON.parse(JSON.stringify(canadaData));
const usList = JSON.parse(JSON.stringify(usData));

export interface IHomeFilter {
    companyName?: string
    businessPhone?: string
    ownerName?: string
    ownerPhone?: string
    city?: string
    state?: string
    country?: string
    address?: string
    postalCode?: string
}

const HomeFilter = () => {
    const [queryParams, setQueryParamsAndPush] = useQueryParams()
    const [companyName, setCompanyName] = useState<string | null>(null)
    const [businessPhone, setBusinessPhone] = useState<string | null>(null)
    const [ownerName, setOwnerName] = useState<string | null>(null)
    const [ownerPhone, setOwnerPhone] = useState<string | null>(null)
    const [city, setCity] = useState<string | null>(null)
    const [state, setState] = useState<string | null>(null)
    const [country, setCountry] = useState<string | null>(null)
    const [address, setAddress] = useState<string | null>(null)
    const [postalCode, setPostalCode] = useState<string | null>(null)

    const [showFilter, setShowFilter] = useState(false)

    useEffect(() => {
        if (!queryParams) return
        setCompanyName(queryParams.companyName || null)
        setBusinessPhone(queryParams.businessPhone || null)
        setOwnerName(queryParams.ownerName || null)
        setOwnerPhone(queryParams.ownerPhone || null)
        setCity(queryParams.city || null)
        setState(queryParams.state || null)
        setCountry(queryParams.country || null)
        setAddress(queryParams.address || null)
        setPostalCode(queryParams.postalCode || null)
    },[queryParams])

    const onFilter = () => {
        const filters = {
            companyName: companyName?.trim(),
            businessPhone: businessPhone?.trim(),
            ownerName: ownerName?.trim(),
            ownerPhone: ownerPhone?.trim(),
            city: city?.trim(),
            state: state?.trim(),
            country: country?.trim(),
            address: address?.trim(),
            postalCode: postalCode?.trim(),
        }
        setQueryParamsAndPush({...queryParams, ...filters, page: 1}, true)
    }

    const onClear = () => {
        setCompanyName('')
        setBusinessPhone('')
        setOwnerName('')
        setOwnerPhone('')
        setCity('')
        setState('')
        setCountry('')
        setQueryParamsAndPush({page: DEFAULT_PAGINATE.page, limit: DEFAULT_PAGINATE.limit, companyName: null,businessPhone: null,ownerName: null,ownerPhone: null, city: null, state: null, country: null}, true)
    }

    const onKeyDown = (event: any) => {
        const code = event.code
        if (code === 'Enter') {
            onFilter()
        }
    }


    return (
        <div className={'mb-5 max-w-full'}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

                    <button type="button" className="group text-gray-700 font-medium flex items-center sm:hidden" aria-controls="disclosure-1" onClick={() => setShowFilter(!showFilter)}>
                        <img src={FilterImg} alt="" className={'w-6 h-6'}/>
                        Filters
                    </button>

                    <div className={`sm:grid grid-cols-6 gap-2 sm:gap-6 ${showFilter ? 'grid' : 'hidden'}`}>
                        <div className="col-span-6 sm:col-span-2">
                            <input
                                value={companyName || ''}
                                placeholder={'Company Name'}
                                type="text"
                                onChange={e => setCompanyName(e.target.value)}
                                onKeyDown={onKeyDown}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                            <input
                                value={businessPhone || ''}
                                placeholder={'Business Phone'}
                                type="text"
                                onChange={e => setBusinessPhone(e.target.value)}
                                onKeyDown={onKeyDown}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                            <input
                                value={ownerName || ''}
                                placeholder={'Owner Name'}
                                type="text"
                                onChange={e => setOwnerName(e.target.value)}
                                onKeyDown={onKeyDown}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                            <input
                                value={ownerPhone || ''}
                                placeholder={'Owner Phone'}
                                type="text"
                                onChange={e => setOwnerPhone(e.target.value)}
                                onKeyDown={onKeyDown}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                            <input
                                value={address || ''}
                                placeholder={'Address'}
                                type="text"
                                onChange={e => setAddress(e.target.value)}
                                onKeyDown={onKeyDown}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                            <input
                                value={postalCode || ''}
                                placeholder={'Postal Code'}
                                type="text"
                                onChange={e => setPostalCode(e.target.value)}
                                onKeyDown={onKeyDown}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                            <input
                                value={city || ''}
                                placeholder={'City'}
                                type="text"
                                onChange={e => setCity(e.target.value)}
                                onKeyDown={onKeyDown}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div className="col-span-6 sm:col-span-2">

                            {country === 'US' || country === 'CA' ?
                                <select
                                    value={state || ''}
                                    placeholder={'State'}
                                    onChange={e => setState(e.target.value)}
                                    className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                >
                                    <option value={''}>All State</option>
                                    {country === 'US' && usList.map((country: any) => <option key={country.abbreviation} value={country.abbreviation}>{country.name}</option>)}
                                    {country === 'CA' && canadaList.map((country: any) => <option key={country.abbreviation} value={country.abbreviation}>{country.name}</option>)}
                                </select> :
                                <input
                                    value={state || ''}
                                    placeholder={'State'}
                                    type="text"
                                    onChange={e => setState(e.target.value)}
                                    onKeyDown={onKeyDown}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                            }
                        </div>

                        <div className="col-span-6 sm:col-span-2">
                            <select
                                value={country || ''}
                                placeholder={'Country'}
                                onChange={e => {
                                    setCountry(e.target.value)
                                    setState(null)
                                }}
                                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value={''}>All Country</option>
                                {countryCodeArr.map(country => <option key={country.code} value={country.code}>{country.name}</option>)}
                            </select>
                        </div>

                        <div className="col-span-6 sm:col-span-6 text-right flex flex-row justify-end mt-1">
                            <button onClick={onClear} className="bg-white border border-indigo-600 rounded-md shadow-sm py-2 px-4 mr-2 inline-flex justify-center text-sm font-medium text-indigo-600 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Clear
                            </button>
                            <button
                                onClick={onFilter}
                                className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default HomeFilter;